import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@nfs/ui-kit/Alert';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Box from '@nfs/ui-kit/Box';
import Button from '@nfs/ui-kit/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import Skeleton from '@material-ui/lab/Skeleton';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@nfs/ui-kit/TextField';
import Typography from '@nfs/ui-kit/Typography';

import Link from '../link';
import SmartCardUsers from './smart-card-users';
import Notice from './notice';
import styles from './styles.module.scss';

const schema = yup.object().shape({
    username: yup.string().required('Field required.'),
    password: yup.string().required('Field required.')
});

/**
 * Login screen component
 */
const Login = props => {
    const {
        authenticating,
        customerServiceEmailAddress,
        customerServicePhoneNumber,
        forgotPasswordLink,
        forgotUsernameLink,
        helperText,
        loading,
        onSignIn,
        phoneSupportOperationHours,
        samlLink,
        smartCardLink
    } = props;

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        setOpenAlert(Boolean(helperText));
    }, [helperText]);

    return (
        <section className={styles.body}>
            <Box width={200}>
                <form
                    method="post"
                    action="/not-supported"
                    onSubmit={handleSubmit(data => onSignIn(data))}
                    className={styles.form}
                >
                    <h1 className={styles.formTitle}>Agent Sign In</h1>
                    {loading && (
                        <>
                            <Box width="100%">
                                <Skeleton
                                    variant="rect"
                                    height={15}
                                    width={100}
                                />
                            </Box>
                            <Box width="100%" mt={1}>
                                <Skeleton variant="rect" height={40} />
                            </Box>
                            <Box width="100%" mt={4}>
                                <Skeleton
                                    variant="rect"
                                    height={15}
                                    width={100}
                                />
                            </Box>
                            <Box width="100%" mt={1}>
                                <Skeleton variant="rect" height={40} />
                            </Box>
                            <Box width="100%" mt={4}>
                                <Skeleton variant="rect" height={50} />
                            </Box>
                        </>
                    )}
                    {!loading && samlLink && (
                        <>
                            <Box mt={4} width="100%">
                                <Typography component="p" gutterBottom>
                                    Click the button below to sign in.
                                </Typography>
                                <Button
                                    fullWidth
                                    disabled={authenticating}
                                    loading={authenticating}
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    className={`App-submitBtn App-loginBtn ${styles.btn}`}
                                    endIcon={<ArrowForwardIcon />}
                                    href={samlLink}
                                >
                                    Sign in
                                </Button>
                            </Box>
                        </>
                    )}
                    {!loading && !samlLink && (
                        <>
                            <Controller
                                render={field => (
                                    <TextField
                                        {...field}
                                        autoFocus
                                        fullWidth
                                        error={Boolean(errors?.username)}
                                        helperText={
                                            errors.username && (
                                                <Box
                                                    component="span"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <ErrorOutlineIcon />
                                                    <Box
                                                        component="span"
                                                        ml={1}
                                                    >
                                                        {
                                                            errors.username
                                                                .message
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        id="username"
                                        InputLabelProps={{
                                            htmlFor: 'username'
                                        }}
                                        label="Username"
                                        type="text"
                                        className={styles.formInput}
                                    />
                                )}
                                control={control}
                                name="username"
                                defaultValue=""
                            />
                            <Controller
                                render={({ value, ...field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={Boolean(errors?.password)}
                                        helperText={
                                            errors.password && (
                                                <Box
                                                    component="span"
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <ErrorOutlineIcon />
                                                    <Box
                                                        component="span"
                                                        ml={1}
                                                    >
                                                        {
                                                            errors.password
                                                                .message
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        id="password"
                                        InputLabelProps={{
                                            htmlFor: 'password'
                                        }}
                                        label="Password"
                                        type="password"
                                        margin="normal"
                                        className={styles.formInput}
                                    />
                                )}
                                control={control}
                                name="password"
                                defaultValue=""
                            />
                            <Box mt={4} width="100%">
                                <Button
                                    fullWidth
                                    disabled={authenticating}
                                    loading={authenticating}
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className={`App-submitBtn App-loginBtn ${styles.btn}`}
                                    endIcon={<ArrowForwardIcon />}
                                >
                                    Sign in
                                </Button>
                            </Box>
                        </>
                    )}
                    {openAlert && (
                        <Box mt={4}>
                            <Alert
                                open={openAlert}
                                onClose={() => setOpenAlert(false)}
                                elevation={6}
                                severity="error"
                                variant="outlined"
                            >
                                {helperText}
                            </Alert>
                        </Box>
                    )}
                    {forgotUsernameLink && (
                        <span className={styles.forgotPasswordLink}>
                            <Link
                                href={forgotUsernameLink}
                                className={styles.forgotPasswordLink}
                            >
                                Forgot username?
                            </Link>
                        </span>
                    )}

                    {forgotPasswordLink && (
                        <span className={styles.forgotPasswordLink}>
                            <Link
                                href={forgotPasswordLink}
                                className={styles.forgotPasswordLink}
                            >
                                Forgot password?
                            </Link>
                        </span>
                    )}
                    {smartCardLink && <SmartCardUsers link={smartCardLink} />}
                </form>
            </Box>
            <div className={styles.contacts}>
                {loading && (
                    <>
                        <Box>
                            <Skeleton variant="rect" height={30} width={375} />
                        </Box>
                        <Box mt={2}>
                            <Skeleton variant="rect" height={15} width={250} />
                        </Box>
                        <Box mt={8}>
                            <Skeleton variant="rect" height={30} width={375} />
                        </Box>
                        <Box mt={2}>
                            <Skeleton variant="rect" height={15} width={250} />
                        </Box>
                    </>
                )}
                {!loading && customerServicePhoneNumber && (
                    <div className={styles.contactsItem}>
                        <div className={styles.contactsItemPrimary}>
                            <SvgIcon fontSize="large">
                                <PhoneIcon />
                            </SvgIcon>
                            {customerServicePhoneNumber}
                        </div>
                        <div className={styles.contactsItemSecondary}>
                            {phoneSupportOperationHours}
                        </div>
                    </div>
                )}
                {!loading && customerServiceEmailAddress && (
                    <div className={styles.contactsItem}>
                        <div className={styles.contactsItemPrimary}>
                            <SvgIcon fontSize="large">
                                <AlternateEmailIcon />
                            </SvgIcon>
                            <a href={`mailto:${customerServiceEmailAddress}`}>
                                {customerServiceEmailAddress}
                            </a>
                        </div>
                    </div>
                )}
                {smartCardLink && (
                    <section className={styles.body}>
                        <Notice />
                    </section>
                )}
            </div>
        </section>
    );
};

Login.propTypes = {
    authenticating: PropTypes.bool,
    customerServiceEmailAddress: PropTypes.string,
    customerServicePhoneNumber: PropTypes.string,
    forgotPasswordLink: PropTypes.string,
    forgotUsernameLink: PropTypes.string,
    helperText: PropTypes.string,
    loading: PropTypes.bool,
    onSignIn: PropTypes.func,
    phoneSupportOperationHours: PropTypes.string,
    samlLink: PropTypes.string,
    smartCardLink: PropTypes.string
};

Login.defaultProps = {
    authenticating: false,
    customerServiceEmailAddress: '',
    customerServicePhoneNumber: '',
    forgotPasswordLink: '',
    forgotUsernameLink: '',
    helperText: '',
    loading: false,
    onSignIn: () => {},
    phoneSupportOperationHours: 'M–F from 8am to 8pm EST',
    samlLink: '',
    smartCardLink: ''
};

export default memo(Login);
