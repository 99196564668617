import _has from 'lodash/has';

export function toGoogleTypeDate(date) {
    if (!date || !(date instanceof Date)) {
        return null;
    }

    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
}

export function asNativeDateFromGoogleTypeDate(googleDateObject) {
    if (!googleDateObject) {
        return null;
    }

    if (googleDateObject instanceof Date) {
        return googleDateObject;
    }

    const hasDateProperties =
        _has(googleDateObject, 'year') &&
        _has(googleDateObject, 'month') &&
        _has(googleDateObject, 'day');

    if (!hasDateProperties) {
        return null;
    }

    return new Date(
        googleDateObject.year,
        googleDateObject.month - 1,
        googleDateObject.day
    );
}
