import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    backdrop: ({ isRelative }) => {
        const otherProps = {};

        if (isRelative) {
            otherProps.position = 'absolute';
        }

        return {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
            ...otherProps
        };
    },
    progress: {
        color: 'rgba(255, 255, 255, 0.9)',
        marginRight: 8
    }
}));

const LoadingScreen = props => {
    const { label, open = false, isRelative } = props;
    const classes = useStyles({ isRelative });

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress
                thickness={4}
                className={classes.progress}
                size={16}
            />
            <Typography variant="subtitle1">{label}</Typography>
        </Backdrop>
    );
};

LoadingScreen.propTypes = {
    label: PropTypes.string,
    open: PropTypes.bool,
    isRelative: PropTypes.bool
};

LoadingScreen.defaultProps = {
    label: null,
    open: false,
    isRelative: false
};

export default LoadingScreen;
