import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isoURL from 'url';
import axios from 'axios';
import AES from 'crypto-js/aes';
import Box from '@nfs/ui-kit/Box';
import fetch from 'isomorphic-unfetch';
import Layout from '../components/layouts/public';
import ContinuingEducationAttestationDialog from '../components/dialogs/continuing-education-attestation-dialog';
import { withApollo } from '../lib/apollo';
import Login from '../components/login';
import LoadingScreen from '../components/loadingScreen';
import { CONTINUING_EDUCATION_COPY } from '../lib/constants';
import AssurantOutDialog from '../components/dialogs/assurant-out-dialog';
import ForemostSuspensionAttestationDialog from '../components/dialogs/foremost-suspension-dialog';
import AgentAccessDeniedDialog from '../components/dialogs/agent-access-denied-dialog';
import { toGoogleTypeDate } from '../../libs/grpc-utils';

const { ALLSTATE_COPY, FARMERS_COPY } = CONTINUING_EDUCATION_COPY;

const { publicRuntimeConfig } = getConfig() ?? {};
const {
    NEXT_PUBLIC_AES_SECRET_KEY,
    NEXT_PUBLIC_ALLSTATE_PREPEND_LABEL,
    NEXT_PUBLIC_ALLSTATE_TENANTS,
    NEXT_PUBLIC_DEFAULT_FORGOT_PASSWORD_URL,
    NEXT_PUBLIC_DEFAULT_FORGOT_USERNAME_URL,
    NEXT_PUBLIC_DEFAULT_ROUTE_URL,
    NEXT_PUBLIC_DEFAULT_THEME_NAME,
    NEXT_PUBLIC_FARMERS_TENANTS,
    NEXT_PUBLIC_FLOODPRO_DISABLED,
    NEXT_PUBLIC_REFRESH_TOKEN_COOKIE,
    ACCESS_TOKEN_COOKIE,
    NEXT_PUBLIC_ASSURANT_OUT_DIALOG_FEATURE_FLAG,
    NEXT_PUBLIC_SECURE,
    NEXT_PUBLIC_FARMERS_FOREMOST_SUSPENSION_FEATURE_FLAG
} = publicRuntimeConfig ?? {};

export const PAGE_LOAD_QUERIES = gql`
    query LoginPageLoad($hostname: String!, $defaultThemeName: String) {
        tenant: lookupTenantByHostname(
            hostname: $hostname
            defaultThemeName: $defaultThemeName
        ) {
            companyName
            tenantCode
            themeName
            metadata: publicTenantMetadata {
                phone(format: "1-NNN-NNN-NNNN")
                email
                floodProUri
                forgotPasswordUri
                forgotUsernameUri
                samlSignInUri
                smartCardSignInUri
            }
        }
    }
`;

const CONFIRM_CONTINUING_EDUCATION = gql`
    mutation {
        confirmContinuingEducation {
            success {
                identifiers {
                    dataVersion
                }
            }
        }
    }
`;

export const SEND_SUSPENSION_DEBARMENT_EMAIL = gql`
    query SendSuspensionDebarmentEmail($username: String!) {
        sendSuspensionDebarmentEmail(username: $username)
    }
`;

const CONFIRM_CONTINUING_EDUCATION_FARMERS = gql`
    query GetURL($next: String!, $tenantCode: String!) {
        getContinuingEducationTrainingUrl(next: $next, tenantCode: $tenantCode)
    }
`;

/**
 * Login Page definition
 */
const LoginPage = props => {
    const router = useRouter();
    const { hostname, nextUrl } = props;
    const [helperText, setHelperText] = useState('');
    const [usernameState, setUsernameState] = useState('');
    const [redirectingLoading, setRedirectLoading] = useState(false);
    const [showCEAttestation, setShowCEAttestation] = useState(false);
    const [CEData, setCEData] = useState(null);
    const [showAccessDeniedDialog, setShowAccessDeniedDialog] = useState(false);
    const [
        showFarmersForemostSuspension,
        setShowFarmersForemostSuspension
    ] = useState(false);
    const [data, setData] = useState(null);
    const [showCouldNotObtainQuote, setShowCouldNotObtainQuote] = useState(
        false
    );
    const [isAllstateTenant, setIsAllstateTenant] = useState(false);
    const [isFarmersTenant, setIsFarmersTenant] = useState(false);
    const [authenticationLoading, setAuthenticationLoading] = useState(false);
    const AssurantTenantCode = '10111';

    const {
        data: pageLoadData,
        error: pageLoadError,
        loading: pageLoadLoading
    } = useQuery(PAGE_LOAD_QUERIES, {
        variables: {
            hostname,
            defaultThemeName: NEXT_PUBLIC_DEFAULT_THEME_NAME
        }
    });

    const SET_SUSPENSION_DEBARMENT_FOR_USERNAME = gql`
        mutation SetSuspensionDebarmentForUsername(
            $input: ModelsUserprofileV1_SetSuspensionDebarmentForUsernameRequestInput!
        ) {
            setSuspensionDebarmentForUsername(input: $input) {
                success {
                    identifiers {
                        username
                    }
                }
                error {
                    text
                }
            }
        }
    `;

    const [setSuspensionDebarmentForUsername] = useMutation(
        SET_SUSPENSION_DEBARMENT_FOR_USERNAME,
        {
            onCompleted: () => {}
        }
    );
    const { tenant } = pageLoadData ?? {};

    const [
        confirmContinuingEducation,
        { loading: confirmContinuingEducationLoading }
    ] = useMutation(CONFIRM_CONTINUING_EDUCATION);
    const [confirmFarmersEducation] = useLazyQuery(
        CONFIRM_CONTINUING_EDUCATION_FARMERS,
        {
            variables: {
                tenantCode: tenant?.tenantCode,
                next: nextUrl
            },
            onCompleted: ({ getContinuingEducationTrainingUrl }) => {
                window.location.href = getContinuingEducationTrainingUrl;
            }
        }
    );

    const logout = async () => {
        const response = await fetch('/logout', {
            method: 'GET',
            credentials: 'include'
        });
        if (response.ok) {
            return response;
        }
        // convert non-2xx HTTP responses into errors:
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    };

    const [sendSDEmail] = useLazyQuery(SEND_SUSPENSION_DEBARMENT_EMAIL, {
        variables: {
            username: usernameState
        },
        onCompleted: () => {},
        onError: error => {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    });

    useEffect(() => {
        window.localStorage.removeItem(NEXT_PUBLIC_REFRESH_TOKEN_COOKIE);

        // Prefetch the next page
        if (router.query && router.query.redirect) {
            router.prefetch('router.query.redirect');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tenant) {
            setIsAllstateTenant(
                NEXT_PUBLIC_ALLSTATE_TENANTS?.split(',')?.includes(
                    tenant.tenantCode
                )
            );

            setIsFarmersTenant(
                NEXT_PUBLIC_FARMERS_TENANTS?.split(',')?.includes(
                    tenant.tenantCode
                )
            );
        }
    }, [tenant]);

    /**
     * Prepend the string with a label.
     *
     * @param {*} label
     * @param {*} str
     * @returns
     */
    const prependLabel = (label, str) => {
        let retval = str;

        const regex = new RegExp(
            `^${label.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}`,
            'i'
        );

        if (!str.match(regex)) {
            retval = `${label}${str}`;
        }

        return retval;
    };

    /**
     * Redirect to the next page
     */
    const redirectToNextPage = () => {
        setRedirectLoading(true);

        if (
            router.query &&
            router.query.redirect &&
            router.query !== router.query.redirect
        ) {
            const { url, query } = queryString.parseUrl(router.query.redirect);
            router.push({ pathname: url, query });
        } else {
            router.push({ pathname: NEXT_PUBLIC_DEFAULT_ROUTE_URL });
        }
    };
    const proceedPageLoading = continuingEducation => {
        if (
            continuingEducation?.requireAttestation &&
            !continuingEducation?.hasEnhanced
        ) {
            setShowCEAttestation(true);
        } else if (!showAccessDeniedDialog) {
            redirectToNextPage();
        }
    };
    /**
     * Authenticate user and continue to next page.
     * @param {*}
     */
    const signIn = async ({ username, password }) => {
        try {
            setHelperText('');
            setAuthenticationLoading(true);

            const payload = {
                username: isAllstateTenant
                    ? prependLabel(NEXT_PUBLIC_ALLSTATE_PREPEND_LABEL, username)
                    : username,
                password
            };

            const resp = await axios
                .post('/api/auth', {
                    payload: AES.encrypt(
                        JSON.stringify(payload),
                        NEXT_PUBLIC_AES_SECRET_KEY
                    ).toString()
                })
                .finally(() => {
                    setAuthenticationLoading(false);
                });

            setData(resp.data);

            if (resp.data) {
                const continuingEducation = resp?.data?.continuingEducation;
                setCEData(continuingEducation);
                setUsernameState(username.toLowerCase());

                const userTenants = resp?.data?.userTenants?.map(
                    ({ tenant: tenantName }) => tenantName
                );

                setIsAllstateTenant(
                    NEXT_PUBLIC_ALLSTATE_TENANTS?.split(',')?.some(value =>
                        userTenants?.includes(value)
                    )
                );
                setIsFarmersTenant(
                    NEXT_PUBLIC_FARMERS_TENANTS?.split(',')?.some(value =>
                        userTenants?.includes(value)
                    )
                );
                const suspensionDebarment = resp?.data?.suspensionDebarment;
                if (
                    NEXT_PUBLIC_FARMERS_FOREMOST_SUSPENSION_FEATURE_FLAG ===
                        'true' &&
                    suspensionDebarment
                ) {
                    setShowFarmersForemostSuspension(true);
                } else if (
                    NEXT_PUBLIC_ASSURANT_OUT_DIALOG_FEATURE_FLAG === 'true' &&
                    tenant?.tenantCode === AssurantTenantCode
                ) {
                    // Assurant company only
                    setShowCouldNotObtainQuote(true);
                } else {
                    proceedPageLoading(continuingEducation);
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            setHelperText('Invalid username or password.');
        }
    };

    /**
     * Handle Continuing Education Attestation close
     */
    const handleAttestationClose = () => {
        setShowCEAttestation(false);
        redirectToNextPage();
    };

    const handleCouldNotObtainQuoteClose = () => {
        setShowCouldNotObtainQuote(false);

        // Show second dialog if needed or redirect to next page
        const continuingEducation = data?.continuingEducation;
        proceedPageLoading(continuingEducation);
    };

    /**
     * Handle Continuing Education Attestation submit
     */
    const handleContinuingEducationAttestationSubmit = async () => {
        try {
            setShowCEAttestation(false);
            if (isAllstateTenant) {
                await confirmContinuingEducation();
                redirectToNextPage();
            } else if (isFarmersTenant) {
                await confirmFarmersEducation();
            }
        } catch (error) {
            throw Error(error);
        }
    };

    const handleFFSuspensionAttestationClose = () => {
        setShowAccessDeniedDialog(true);
        sendSDEmail();
        logout().then(r => r);
        window.localStorage.removeItem(NEXT_PUBLIC_REFRESH_TOKEN_COOKIE);
        window.localStorage.removeItem(ACCESS_TOKEN_COOKIE);
    };
    const handleFFSuspensionAttestationSubmit = () => {
        setShowFarmersForemostSuspension(false);
        setSuspensionDebarmentForUsername({
            variables: {
                input: {
                    attestationDate: toGoogleTypeDate(new Date()),
                    certified: true,
                    username: usernameState
                }
            }
        }).then(r => r);

        if (
            NEXT_PUBLIC_ASSURANT_OUT_DIALOG_FEATURE_FLAG === 'true' &&
            tenant?.tenantCode === AssurantTenantCode
        ) {
            // Assurant company only
            setShowCouldNotObtainQuote(true);
        } else {
            proceedPageLoading(CEData);
        }
    };
    return (
        <Layout
            title="Agent Sign In"
            logoAlt={tenant?.companyName}
            logoSrc={`/assets/${tenant?.themeName}/logo.png`}
            loading={pageLoadLoading}
            themeName={
                pageLoadError
                    ? NEXT_PUBLIC_DEFAULT_THEME_NAME
                    : tenant?.themeName
            }
        >
            <Box mt={-46}>
                <Login
                    samlLink={tenant?.metadata?.samlSignInUri}
                    smartCardLink={tenant?.metadata?.smartCardSignInUri}
                    authenticating={
                        authenticationLoading ||
                        confirmContinuingEducationLoading
                    }
                    customerServiceEmailAddress={
                        tenant?.metadata?.email || 'CustomerService@myflood.com'
                    }
                    customerServicePhoneNumber={
                        tenant?.metadata?.phone || '1-800-555-5555'
                    }
                    helperText={helperText}
                    loading={pageLoadLoading}
                    onSignIn={signIn}
                    phoneSupportOperationHours="M–F from 8am to 8pm EST"
                    forgotPasswordLink={
                        router.query.forgot
                            ? '/forgot-password'
                            : tenant?.metadata?.forgotPasswordUri ||
                              NEXT_PUBLIC_DEFAULT_FORGOT_PASSWORD_URL
                    }
                    forgotUsernameLink={
                        NEXT_PUBLIC_FLOODPRO_DISABLED.toLowerCase() !== 'true'
                            ? tenant?.metadata?.forgotUsernameUri ||
                              NEXT_PUBLIC_DEFAULT_FORGOT_USERNAME_URL
                            : ''
                    }
                />
            </Box>
            <ContinuingEducationAttestationDialog
                copy={isFarmersTenant ? FARMERS_COPY : ALLSTATE_COPY}
                open={showCEAttestation}
                onClose={handleAttestationClose}
                onSubmit={handleContinuingEducationAttestationSubmit}
            />
            <ForemostSuspensionAttestationDialog
                open={showFarmersForemostSuspension}
                onClose={handleFFSuspensionAttestationClose}
                onSubmit={handleFFSuspensionAttestationSubmit}
            />
            <AgentAccessDeniedDialog open={showAccessDeniedDialog} />
            <AssurantOutDialog
                open={showCouldNotObtainQuote}
                onClose={handleCouldNotObtainQuoteClose}
            />
            <LoadingScreen
                open={redirectingLoading || confirmContinuingEducationLoading}
            />
        </Layout>
    );
};

LoginPage.propTypes = {
    hostname: PropTypes.string,
    nextUrl: PropTypes.string
};

LoginPage.defaultProps = {
    hostname: '',
    nextUrl: ''
};

LoginPage.getInitialProps = async ({ req }) => {
    const hostname = req
        ? new URL(`${req.protocol}://${req.headers.host}`).hostname
        : window.location.hostname;
    const uri = req
        ? req.originalUrl
        : `${new URL(window.location.href).pathname}${
              new URL(window.location.href).search
          }`;

    const currentProtocol = NEXT_PUBLIC_SECURE ? 'https://' : 'http://';
    const { protocol, host, query } = isoURL.parse(
        `${currentProtocol}${hostname}${uri}`
    );
    const { redirect } = queryString.parse(query);
    const nextUrl = isoURL.format({
        protocol,
        host,
        pathname: redirect || NEXT_PUBLIC_DEFAULT_ROUTE_URL
    });

    return {
        hostname,
        nextUrl
    };
};

export default withApollo({ ssr: true })(LoginPage);
