import PropTypes from 'prop-types';
import Box from '@nfs/ui-kit/Box';
import Dialog from '@nfs/ui-kit/Dialog';
import Grid from '@nfs/ui-kit/Grid';
import Typography from '@nfs/ui-kit/Typography';
import { Link } from '@nfs/ui-kit';
import { InfoOutlined } from '@material-ui/icons';
import styles from './styles.module.scss';
/**
 * Agent access denied dialog
 *
 * @param {*} props
 * @returns
 */
const AgentAccessDeniedDialog = props => {
    const { open, title } = props;
    const suspensionArticleLink =
        'https://www.gsa.gov/policy-regulations/policy/acquisition-policy/office-of-acquisition-policy/gsa-acq-policy-integrity-workforce/suspension-debarment-and-agency-protests/frequently-asked-questions-suspension-debarment';
    return (
        <Dialog
            scroll="body"
            maxWidth="sm"
            title={title}
            open={open}
            icon={<InfoOutlined className={styles.warningIcon} />}
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Box>
                        <Typography component="p" className={styles.deniedText}>
                            Agents who are suspended or debarred from doing
                            business with the federal government cannot
                            participate in the National Flood Insurance Program.
                        </Typography>
                        <Typography component="p" className={styles.deniedText}>
                            Click{' '}
                            <Link href={suspensionArticleLink} target="_blank">
                                here
                            </Link>{' '}
                            for information about Suspension or Debarment
                        </Typography>
                        <Typography
                            component="p"
                            className={styles.closeBrowser}
                        >
                            If you made the selection in error, close the
                            browser and try again.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

AgentAccessDeniedDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string
};

AgentAccessDeniedDialog.defaultProps = {
    open: false,
    title: 'Access Denied'
};

export default AgentAccessDeniedDialog;
