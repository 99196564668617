import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@material-ui/core';

const SmartCardUsers = props => {
    const { link } = props;

    return (
        <Box mt={5}>
            <Box mb={3}>
                <Divider variant="middle" />
            </Box>
            <Typography variant="h6" component="h2">
                Smart Card Users
            </Typography>
            <Box pt={2}>
                <Typography component="p">
                    Insert your smart-card, and click below.
                </Typography>
            </Box>
            {link && (
                <Box align="center">
                    <a href={link}>
                        <img src="/assets/piv-small.png" alt="PIV card" />
                    </a>
                </Box>
            )}
        </Box>
    );
};

SmartCardUsers.propTypes = {
    link: PropTypes.string.isRequired
};

export default SmartCardUsers;
