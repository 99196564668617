import PropTypes from 'prop-types';
import Box from '@nfs/ui-kit/Box';
import Button from '@nfs/ui-kit/Button';
import Dialog from '@nfs/ui-kit/Dialog';
import Grid from '@nfs/ui-kit/Grid';
import Typography from '@nfs/ui-kit/Typography';

/**
 * Continuing Education Attestation dialog
 *
 * @param {*} props
 * @returns
 */
const AssurantOutDialog = props => {
    const { loading, onClose, open } = props;

    return (
        <Dialog
            scroll="body"
            maxWidth="sm"
            title="URGENT – COMING THIS FALL"
            open={open}
            actions={
                <Button
                    color="primary"
                    disabled={loading}
                    loading={loading}
                    onClick={onClose}
                >
                    Ok
                </Button>
            }
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Box>
                        <Typography component="p">
                            Assurant will be launching a new and improved
                            experience for servicing and selling your flood
                            policies. Stay tuned for more information.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

AssurantOutDialog.propTypes = {
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool
};

AssurantOutDialog.defaultProps = {
    loading: false,
    onClose: () => {},
    open: false
};

export default AssurantOutDialog;
