import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Skeleton from '@material-ui/lab/Skeleton';
import TwitterIcon from '@material-ui/icons/Twitter';

import { getHeadTitle } from '../../../utils/helpers/PageHelper';
import Link from '../../link';
import styles from './styles.module.scss';

const NFS_URL = 'https://nationalfloodservices.com';
const NFS_TITLE = 'National Flood Services';
const NFS_LOGO = '/assets/logo-nfs.jpg';

/**
 * Public-facing layout.
 *
 * @param {*} props
 */
const PublicLayout = props => {
    const { children, title, logoSrc, logoAlt, loading, themeName } = props;
    const [agencyBackgroundImgSrc, setAgencyBackgroundImgSrc] = useState('');

    useEffect(() => {
        if (themeName) {
            setAgencyBackgroundImgSrc(`/assets/${themeName}/wallpaper.jpg`);
        }
    }, [themeName]);

    return (
        <div className={styles.root}>
            {title && (
                <Head>
                    <title>{getHeadTitle(title)}</title>
                </Head>
            )}
            <header className={styles.header}>
                <span className={styles.headerLogo}>
                    {loading ? (
                        <Skeleton variant="rect" height={40} width={200} />
                    ) : (
                        <Link href="/">
                            <img
                                src={logoSrc}
                                alt={`${logoAlt} logo` || 'logo'}
                            />
                        </Link>
                    )}
                </span>
                <nav className={styles.headerMenu}>
                    <a
                        href={`${NFS_URL}/oursolutions`}
                        className={styles.headerMenuItem}
                    >
                        Solutions
                    </a>
                    <a
                        href={`${NFS_URL}/about-us/nfsstory`}
                        className={styles.headerMenuItem}
                    >
                        About Us
                    </a>
                </nav>
            </header>
            <div>
                <section
                    className={styles.background}
                    style={{
                        backgroundImage: `url(${agencyBackgroundImgSrc})`
                    }}
                />
                {children}
            </div>
            <footer className={styles.footer}>
                <div className={styles.poweredBy}>
                    Powered by{' '}
                    <a href={NFS_URL}>
                        <img
                            src="/assets/nfs/logo.png"
                            alt="National Flood Services"
                        />
                    </a>
                </div>
                <nav className={styles.footerMenu}>
                    <a
                        href={`${NFS_URL}/courses/faqs/?fb-edit=1`}
                        className={styles.footerMenuItem}
                    >
                        FAQ
                    </a>
                    <a
                        href={`${NFS_URL}/contact-us/?fb-edit=1`}
                        className={styles.footerMenuItem}
                    >
                        Contact Us
                    </a>
                    <a
                        href={`${NFS_URL}/privacy-policy`}
                        className={styles.footerMenuItem}
                    >
                        Privacy Policy
                    </a>
                </nav>
                <div className={styles.copyright}>
                    Copyright &copy; National Flood Services LLC{' '}
                    {new Date().getFullYear()}. All rights reserved. This
                    website is for general information purposes only and is not
                    intended to provide legal or regulatory advice. It is not
                    intended to be a substitute for any National Flood Insurance
                    Program publications.
                </div>
                <div className={styles.social}>
                    <a
                        href="https://www.facebook.com/NationalFloodServices/"
                        className={styles.socialItem}
                    >
                        <FacebookIcon fontSize="small" titleAccess="Facebook" />
                    </a>
                    <a
                        href="https://twitter.com/nationalflood"
                        className={styles.socialItem}
                    >
                        <TwitterIcon fontSize="small" titleAccess="Twitter" />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/nationalfloodservices"
                        className={styles.socialItem}
                    >
                        <LinkedInIcon fontSize="small" titleAccess="LinkedIn" />
                    </a>
                </div>
            </footer>
        </div>
    );
};

PublicLayout.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    logoAlt: PropTypes.string,
    logoSrc: PropTypes.string,
    themeName: PropTypes.string,
    title: PropTypes.string
};

PublicLayout.defaultProps = {
    loading: false,
    logoAlt: NFS_TITLE,
    logoSrc: NFS_LOGO,
    themeName: '',
    title: ''
};

export default PublicLayout;
