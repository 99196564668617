import PropTypes from 'prop-types';
import Box from '@nfs/ui-kit/Box';
import Button from '@nfs/ui-kit/Button';
import Dialog from '@nfs/ui-kit/Dialog';
import Grid from '@nfs/ui-kit/Grid';
import Typography from '@nfs/ui-kit/Typography';
import { Link } from '@nfs/ui-kit';
import { InfoOutlined } from '@material-ui/icons';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import styles from './styles.module.scss';
/**
 * Farmers foremost suspension attestation dialog
 *
 * @param {*} props
 * @returns
 */
const ForemostSuspensionAttestationDialog = props => {
    const { loading, onClose, onSubmit, open, title } = props;
    const suspensionArticleLink =
        'https://www.gsa.gov/policy-regulations/policy/acquisition-policy/office-of-acquisition-policy/gsa-acq-policy-integrity-workforce/suspension-debarment-and-agency-protests/frequently-asked-questions-suspension-debarment';
    return (
        <Dialog
            scroll="body"
            maxWidth="sm"
            title={title}
            open={open}
            actions={
                <>
                    <Button
                        color="secondary"
                        onClick={onClose}
                        variant="outlined"
                        className={styles.warningButton}
                    >
                        <InfoOutlined className={styles.warningIcon} />
                        &nbsp;&nbsp; I am currently debarred or suspended from
                        doing business with the Federal Government
                    </Button>
                    <Button
                        color="primary"
                        disabled={loading}
                        loading={loading}
                        onClick={onSubmit}
                        className={styles.successButton}
                    >
                        <CheckCircleOutlinedIcon
                            className={styles.successIcon}
                        />
                        &nbsp;&nbsp; I am in good standing. I am not suspended
                        or debarred from doing business with the federal
                        government.
                    </Button>
                </>
            }
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Box>
                        <Typography
                            component="p"
                            className={styles.foremostSuspensionText}
                        >
                            Agents are required to notify the insurer should
                            they be suspended or debarred from doing business
                            with the federal government.
                        </Typography>
                        <Typography
                            component="p"
                            className={styles.foremostSuspensionText}
                        >
                            Click{' '}
                            <Link href={suspensionArticleLink} target="_blank">
                                here
                            </Link>{' '}
                            for information about Suspension or Debarment
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

ForemostSuspensionAttestationDialog.propTypes = {
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string
};

ForemostSuspensionAttestationDialog.defaultProps = {
    loading: false,
    onClose: () => {},
    onSubmit: () => {},
    open: false,
    title: 'Suspension or Debarment Attestation'
};

export default ForemostSuspensionAttestationDialog;
